<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit(doSubmit())">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Kode<span class="text-danger mr5">*</span></label>
                  <b-form-input :disabled="$parent.isPageSlugTXT!=='add'" v-model="row.mbu_code" @input="$parent.removeMultiSpace($event,'mbu_code')" placeholder="e.g 0001"
                  @keyup="$parent.removeWildChar"
                  @keydown="$parent.removeWildChar">
                  </b-form-input>
                  <VValidate 
                    name="Kode" 
                    v-model="row.mbu_code" 
                    :rules="{required:1, min:3, max: 8, numeric:1}"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group>
                  <label>Nama Bidang Usaha EN<span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mbu_desc_en" @input="$parent.removeMultiSpace($event,'mbu_desc_en')" placeholder="e.g Bisnis Kuliner"
                  @keyup="$parent.removeWildChar"
                  @keydown="$parent.removeWildChar">
                  </b-form-input>
                  <VValidate 
                    name="Nama Bidang Usaha EN" 
                    v-model="row.mbu_desc_en" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max: 120}"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Nama Bidang Usaha ID<span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mbu_desc_id" @input="$parent.removeMultiSpace($event,'mbu_desc_id')" placeholder="e.g Bisnis Kuliner"
                  @keyup="$parent.removeWildChar"
                  @keydown="$parent.removeWildChar">
                  </b-form-input>
                  <VValidate 
                    name="Nama Bidang Usaha ID" 
                    v-model="row.mbu_desc_id" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max: 120}"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  methods:{
    doSubmit(){
      return this.$parent.doSubmit(
          "/do/BoMBidangUsaha",
          _.assign({type:this.$parent.isAdd?'add':'update'}, {id: (this.$parent.pageId||this.$parent.pageSlug)}, _.clone(this.row)), 
          (type, resp)=>{
            if(type=='success'){
              if(this.isAccountSetting){
                location.reload()
              }else{
                this.$router.push({name:this.$route.name})
              }
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                    console.log(i)
                  })
                  return this.$swal("Blocked With Validation!", msg)
                }else{
                  return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.status==400){
                return this.$swal({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', 'VForm', this.$refs
      )
    }
  }
}
</script>